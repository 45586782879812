import Vue from 'vue'
import App from './App.vue'
import createRouter from './router'
import Meta from 'vue-meta'
import VueLazyload from 'vue-lazyload'
const loadimage = require('@/assets/news/loading.png')
import '@/utlis/vant'
import '@/utlis/remcommon.js'
import shell from '@/utlis/shell.js'
import global from '@/utlis/global'
import { initializeApp } from 'firebase/app'

const globalState = Vue.observable({
	host: null,
})

import { getAnalytics, logEvent, isSupported } from 'firebase/analytics'
if (typeof window !== 'undefined') {
	// 此代码块只在客户端执行
	const firebaseConfig = window.__FIREBASE_CONFIG__ || shell.firebaseConfig // 从 window 对象获取 Firebase 配置
	let app = null
	let analytics = null

	isSupported().then((result) => {
		if (result) {
			app = initializeApp(firebaseConfig)
			analytics = getAnalytics(app)
			logEvent(analytics, 'in_page')
			console.log('in_page')
		}
	})

	Vue.prototype.$logEvent = (event, params = {}) => {
		console.log(event)
		isSupported().then((result) => {
			if (result && analytics) {
				// 检查 analytics 是否已初始化
				logEvent(analytics, event, params)
			}
		})
	}
	Vue.prototype.$eventrackFb = (msg, method, map = {}) => {
		let params = {
			time: new Date(),
			message: msg,
			method: method,
			...map,
		}
		console.log(params)
		isSupported().then((result) => {
			if (result) {
				logEvent(analytics, msg, params)
			}
		})
	}
} else {
	// 对于服务器端，你可以定义一个简单的函数或者不做任何事情
	Vue.prototype.$logEvent = (event, params = {}) => {
		console.log(`Server Log: ${event}`, params)
	}
	Vue.prototype.$eventrackFb = (event, params = {}) => {
		console.log(`Server Log: ${event}`, params)
	}
}

// simple event
Vue.prototype.$globalState = globalState
Vue.prototype.$global = global
Vue.use(Meta)
Vue.use(VueLazyload, {
	preLoad: 1.3,
	error: loadimage,
	loading: loadimage,
	attempt: 1,
})

export default function createApp() {
	const router = createRouter()
	const app = new Vue({
		router,
		data() {
			return {
				baseInfo: null,
				shell: null,
			}
		},
		// mounted() {
		// 	console.log(window.__DOMAIN_CONFIG__);
		// 	this.shell = window.__DOMAIN_CONFIG__;
		// 	const script = document.createElement('script')
		// 	script.async = true
		// 	script.src = this.shell.scriptUrl
		// 	script.crossOrigin = 'anonymous'
		// 	// script.setAttribute('data-ad-channel', cid);
		// 	document.head.appendChild(script)
		// },
		beforeMount() {
			let host = this.$ssrContext ? this.$ssrContext.host : window['__HOST__']
			console.log(host)
			this.$globalState.host = host
		},
		render: (h) => h(App),
	})
	return {
		app,
		router,
	}
}
